<template>
  <div>
    <v-app>
      <div>
        <v-app-bar app fixed color="primary" dark height="100" flat>
          <v-app-bar-nav-icon class="mx-2">
            <v-img
              style="
                max-width: 70px;
                max-height: 70px;
                width: 70px;
                height: 70px;
              "
              cover
              src="./assets/lucyAvatar2.png"
            ></v-img>
          </v-app-bar-nav-icon>
          <v-toolbar-title>
            <v-row dense>
              <v-col>
                {{ nameAgent }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="text-subtitle-2">
                {{ $t('assistant') }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="text-subtitle-2">
                <span>
                  <img
                    src="./assets/online.png"
                    style="width: 10px; height: 10px"
                  />
                </span>
                {{ $t('online') }}
              </v-col>
            </v-row>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon>mdi-email-outline</v-icon>
          <v-icon @click="minimizar">mdi-minus-box</v-icon>
          <v-icon @click="dialog = true">mdi-close-circle</v-icon>
        </v-app-bar>
        <v-main>
          <v-container fluid style="margin-bottom: 2rem">
            <v-list class="chat-messages" ref="messageList">
              <v-row
                class="ma-1"
                :class="message.isUser ? 'justify-end' : 'justify-start'"
                v-for="(message, index) in messages"
                :key="index"
                :id="index"
              >
                <v-col
                  cols="12"
                  md="6"
                  :class="message.isUser ? 'sent' : 'received'"
                >
                  <v-list-item style="padding: 1px">
                    <v-list-item-content style="padding: 1px">
                      <v-list-item-title
                        v-html="message.user"
                      ></v-list-item-title>
                      <v-col
                        style="padding-top: 0px; padding-bottom: 0px"
                        :cols="12"
                        v-for="(msgInLine, index1) in message.message"
                        :key="index1"
                      >
                        <v-btn
                          block
                          outlined
                          color="primary"
                          v-if="msgInLine.includes('{')"
                          @click="prepareResponse(msgInLine)"
                          >{{
                            msgInLine.replace('{', '').replace('}', '')
                          }}</v-btn
                        >
                        <div v-else>
                          <span v-html="msgInLine"></span>
                        </div>
                      </v-col>
                    </v-list-item-content>
                  </v-list-item>
                  <div class="div-flex justify-end" style="display: flex">
                    <span class="text-caption text-right">{{
                      message.time
                    }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-list>
          </v-container>

          <v-footer fixed app style="margin-top: 2rem" class="footerChat">
            <emoji-picker @emoji="append">
              <div
                slot="emoji-invoker"
                slot-scope="{ events: { click: clickEvent } }"
                @click.stop="clickEvent"
              >
                <button type="button">
                  <v-icon size="26px" class="ml-0 mr-1" color="primary">
                    mdi mdi-emoticon
                  </v-icon>
                </button>
              </div>
              <div slot="emoji-picker" slot-scope="{ emojis, insert }">
                <div class="emoji-picker">
                  <div>
                    <div
                      v-for="(emojiGroup, category) in emojis"
                      :key="category"
                    >
                      <h5>{{ category }}</h5>
                      <div class="emojis">
                        <span
                          v-for="(emoji, emojiName) in emojiGroup"
                          :key="emojiName"
                          @click="insert(emoji)"
                          :title="emojiName"
                          >{{ emoji }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </emoji-picker>

            <v-text-field
              class="inputChat"
              hide-details
              dense
              v-model="message"
              :label="$t('writeYourMessage')"
              outlined
              @keyup.enter="sendMessage"
            >
            </v-text-field>
            <div>
              <v-icon @click="sendMessage" class="sendICon"> mdi-send </v-icon>
            </div>
            <div class="uploadFile">
              <v-btn
                @click="onButtonClick"
                class="buttonUpload d-flex justify-center align-center no-elevation"
                elevation="0"
              >
                <v-icon right dark size="22"> mdi-paperclip </v-icon>
              </v-btn>
              <input
                @change="onFileChanged"
                ref="fileUpload"
                class="d-none"
                type="file"
              />
            </div>
          </v-footer>
        </v-main>
      </div>
    </v-app>
    <v-dialog v-if="dialog" v-model="dialog" width="auto">
      <template v-slot:activator="{ props }">
        <v-btn color="primary" v-bind="props"> Open Dialog </v-btn>
      </template>
      <v-card>
        <v-card-text>{{ $t('outChat') }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" variant="text" @click="dialog = false">
            {{ $t('no') }}
          </v-btn>
          <v-btn color="error" variant="text" @click="endingWebChat">
            {{ $t('yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { CONNECTIONSIGNALR } from './plugins/signalR';
import EmojiPicker from 'vue-emoji-picker';
export default {
  name: 'App',
  data() {
    return {
      username: '',
      messages: [],
      message: '',
      dialog: false,
      nameAgent: process.env.VUE_APP_Name,
    };
  },
  methods: {
    async initChat() {
      await this.startChat();
    },

    append(emoji) {
      this.message += emoji;
    },
    minimizar() {
      window.parent.postMessage('minimizeChat', '*');
    },
    async startChat() {
      await CONNECTIONSIGNALR.invoke('Send_Message', 'Hola');
    },
    async endingWebChat() {
      await CONNECTIONSIGNALR.invoke('Send_Message', 'salida');
      this.messages = [];
      this.message = '';
      this.dialog = false;
      window.parent.postMessage('endChat', '*');
    },
    proccessBold(msg) {
      let message = '';
      let reg1 = /\|\*/g;
      let reg2 = /\*\|/g;
      try {
        for (let i = 0; i <= msg.length; i++) {
          msg[i] = msg[i].replace(reg1, '<b>').replace(reg2, '</b>');
        }
      } catch {
        return msg;
      }
      return message;
    },
    proccessLinks(msg) {
      let message = '';
      let reg1 = /\|\-/g;
      let reg2 = /\-\|/g;
      try {
        for (let i = 0; i <= msg.length; i++) {
          var mySubString = msg[i].substring(
            msg[i].indexOf('|-') + 2,
            msg[i].lastIndexOf('-|')
          );
          msg[i] = msg[i]
            .replace(reg1, `<a href="${mySubString}" target="_blank">`)
            .replace(reg2, '</a>');
        }
      } catch {
        return msg;
      }
      return message;
    },
    async ConnetClientHub() {
      if (CONNECTIONSIGNALR.state == 'Disconnected') {
        await CONNECTIONSIGNALR.start()
          .then(() => {
            CONNECTIONSIGNALR.on('NewMSG', (msg) => {
              let reg = /\|\!~/g;
              let newMessage = msg.split(reg);
              let proccessMessage = this.proccessBold(newMessage);
              proccessMessage = this.proccessLinks(proccessMessage);
              this.messages.push({
                user: process.env.VUE_APP_Name,
                message: proccessMessage,
                isUser: false,
                time: this.localDate(),
              });
              this.scrollToBottom();
            });
          })
          .catch(function (err) {
            console.log(err.toString());
            // setTimeout(this.ConnetClientHub, 5000);
          });
      }
    },
    prepareResponse(msg) {
      if (msg != null || msg != undefined || msg != '') {
        this.message = msg.replace('{', '').replace('}', '');
      }
      this.sendMessage();
    },
    async sendMessage() {
      if (this.message != '') {
        CONNECTIONSIGNALR.invoke('Send_Message', this.message);
        this.messages.push({
          user: this.$t('you'),
          message: [this.message],
          isUser: true,
          time: this.localDate(),
        });
        this.message = '';
        this.scrollToBottom();
      }
    },

    onButtonClick() {
      window.addEventListener('focus', () => {}, {
        once: true,
      });
      this.$refs.fileUpload.click();
    },

    onFileChanged(e) {
      this.fileName = e.target.files[0].name.split(' ').join('_');
      this.selectedFile = e.target.files[0];
      let stringInFile = new FileReader();
      let base64Img = [];
      let imgData = [];

      stringInFile.onloadend = () => {
        base64Img = stringInFile.result.split(',');
        imgData = this.fileName.split('.');
        try {
          let object = {
            file: base64Img[1],
            format: imgData[1],
            file_name: imgData[0].split(' ').join('_'),
          };

          let config = {
            headers: {
              'Content-Type': 'application/json',
            },
          };
          console.log(object);

          this.exc_int
            .post('hornitos/', object, config)
            .then(() => {
              this.$refs.fileUpload.value = null;
              this.message =
                this.message +
                ' ' +
                process.env.VUE_APP_ENV_HORNITOS_IMG +
                '/' +
                this.fileName;
            })
            .catch(() => {
              this.snack = true;
              this.snackColor = 'success';
              this.snackText = 'MainAtendimientoChat.ErrorWhenStoragedFile';
            });
        } catch (error) {
          this.snack = true;
          this.snackColor = 'success';
          this.snackText = 'MainAtendimientoChat.ErrorProcessFile';
        }
      };

      stringInFile.readAsDataURL(this.selectedFile);
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const messageList = this.$refs.messageList.$el;
        messageList.scrollTo({
          top: messageList.scrollHeight,
          behavior: 'smooth',
        });
      });
    },
    localDate() {
      let clock = new Date();
      let hour = clock.getHours();
      let minutes = String(clock.getMinutes()).padStart(2, '0');

      return hour + ':' + minutes;
    },
    receiveMessage(ev) {
      if (ev.data === 'startChat') {
        this.startChat();
      }
    },
  },
  async mounted() {
    let userLang = navigator.language || navigator.userLanguage;
    switch (userLang.substring(0, 2)) {
      case 'en':
        this.$root.$i18n.locale = 'en';
        break;
      case 'pt':
        this.$root.$i18n.locale = 'pt';
        break;
      case 'es':
        this.$root.$i18n.locale = 'es';
        break;
      default:
        this.$root.$i18n.locale = 'en';
        break;
    }

    window.addEventListener('message', this.receiveMessage);

    await this.ConnetClientHub();
  },

  components: {
    EmojiPicker,
  },
};
</script>

<style>
.message {
  display: inline-block;
  padding: 5px;
  border-radius: 5px;
  margin: 5px 0;
  max-width: 50%;
}
.sent {
  background-color: #8ec5ff;
  color: white;
  border-radius: 10px;
  padding: 1px;
}
.received {
  background-color: #f2f2f2;
  color: black;
  border-radius: 10px;
  padding: 1px;
}

.chat-messages {
  height: calc(100vh - 100px);
  overflow-y: auto;
  z-index: 1;
}
.chat-input {
  position: fixed;
  bottom: 0px;
  width: 98%;
  background-color: #f5f5f5;
  border-top: 1px solid #ccc;
}

.sendICon {
  display: block;
  font-size: 3em;
  color: #1976d2;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
  transition: all 300ms linear;
}

.sendICon:hover {
  color: #0e3a66;
}

.footerChat {
  display: flex;
}

.inputChat {
  width: 40%;
}

.uploadFile {
  margin: 0;
  padding: 0;
  justify-content: end;
}

.wrapper {
  position: relative;
  display: inline-block;
}

.regular-input {
  padding: 0.5rem 1rem;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 20rem;
  height: 12rem;
  outline: none;
}

.regular-input:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.emoji-invoker {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;
  padding: 0;
  background: transparent;
  border: 0;
}

.emoji-invoker:hover {
  transform: scale(1.1);
}
.emoji-invoker > svg {
  fill: #b1c6d0;
}

.emoji-picker {
  position: absolute;
  z-index: 1;
  font-family: Montserrat;
  border: 1px solid #ccc;
  width: 15rem;
  height: 20rem;
  overflow: scroll;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 1px 1px 8px #c7dbe6;
  /* Cambia la propiedad top por bottom */
  bottom: 30px; /* Ajusta este valor según sea necesario */
}
.emoji-picker__search {
  display: flex;
}
.emoji-picker__search > input {
  flex: 1;
  border-radius: 10rem;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  outline: none;
}
.emoji-picker h5 {
  margin-bottom: 0;
  color: #b1b1b1;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: default;
}
.emoji-picker .emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.emoji-picker .emojis:after {
  content: '';
  flex: auto;
}
.emoji-picker .emojis span {
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
}
.emoji-picker .emojis span:hover {
  background: #ececec;
  cursor: pointer;
}
</style>
