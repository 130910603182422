import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import VueI18n from 'vue-i18n';
import en from '../src/translations/en.json';
import es from '../src/translations/es.json';
import pt from '../src/translations/pt.json';

const messages = {
  en,
  es,
  pt,
};

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'en',
  messages,
});

Vue.config.productionTip = false;

new Vue({
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
